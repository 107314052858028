import { BrickPublishJob } from 'components/bricks/types/brick-publish.type';
import { JobStatus } from 'components/bricks/types/publish.type';

// Helper class for managing the status of the Bricks Publish Tab
class BricksPublishTabStatusHelpers {
    // Calculate the average progress of all running or waiting publish jobs.
    // If there are no such jobs, return 0. This is useful for providing a
    // progress indicator in the UI.
    static calculateProgress(publishJobs: BrickPublishJob[]): number {
        const jobWithNoTasks = publishJobs.find((job) => job.totalTasks === 0);
        // Return 0 if one of the jobs has no tasks, this will prevent the progress bar from going back and forward.
        if (jobWithNoTasks) return 0;

        const totalTasks = publishJobs.reduce((acc, job) => acc + job.totalTasks, 0);
        const finishedTasks = publishJobs.reduce((acc, job) => acc + job.finishedTasks, 0);
        const failedTasks = publishJobs.reduce((acc, job) => acc + job.failedTasks, 0);

        // Calculate the progress
        const progress = totalTasks > 0 ? (finishedTasks + failedTasks) / totalTasks : 0;
        return progress * 100;
    }
}

export { BricksPublishTabStatusHelpers };
