import React from 'react';
import { IconButtonProps, IconProps } from '@mui/material';
import Icon from 'components/ui-components-v2/Icon';
import IconButton from 'components/ui-components-v2/IconButton';

interface Classes {
    root?: string;
    icon?: string;
}
interface Props {
    isIconDown: boolean;
    classes?: Classes;
    fontSize?: IconProps['fontSize'];
    buttonSize?: IconButtonProps['size'];
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

/**
 * This component is responsible for switching between the up and down arrow icon.
 */
const DropDownIconButton: React.FC<Props> = ({ isIconDown, classes, fontSize, buttonSize, onClick }) => {
    const icon = isIconDown ? 'arrow_drop_up' : 'arrow_drop_down';

    return (
        <IconButton size={buttonSize} className={`${classes?.root}`} onClick={onClick}>
            <Icon fontSize={fontSize} className={`${classes?.icon}`}>
                {icon}
            </Icon>
        </IconButton>
    );
};

export default DropDownIconButton;
