import React from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import './../styles/status-icon.scss';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
interface StatusIconProps {
    status: 'success' | 'publishing' | 'error' | 'partialerror' | 'readyToPublish';
}

const StatusIcon: React.FC<StatusIconProps> = ({ status }) => {
    const getIcon = () => {
        if (status === 'success') return 'check_circle';
        else if (status === 'error' || status === 'partialerror') return 'error_outlined';
        else if (status === 'readyToPublish') return 'publish';

        return 'info_outlined';
    };

    return (
        <div
            className={classNames('status_icon', {
                'status_icon--success': status === 'success',
                'status_icon--info': status === 'publishing',
                'status_icon--readyToPublish': status === 'readyToPublish',
                'status_icon--error': status === 'error' || status === 'partialerror'
            })}>
            {status === 'publishing' && (
                <div className="status_icon__publishing">
                    <CircularProgress size={24} />
                </div>
            )}
            {status !== 'publishing' && (
                <Icon
                    fontSize="medium"
                    className={classNames('status_icon__icon', {
                        'status_icon__icon--success': status === 'success',
                        'status_icon__icon--readyToPublish': status === 'readyToPublish',
                        'status_icon__icon--error': status === 'error' || status === 'partialerror'
                    })}>
                    {getIcon()}
                </Icon>
            )}
        </div>
    );
};

export default StatusIcon;
