import React, { useState } from 'react';
import { Brick } from 'components/bricks/types/brick.type';
import { BrickPublishJobData, useBricksPublish } from 'components/bricks/hooks/useBricksPublish';
import OutputJobsDialog from 'components/bricks/components/publish/components/output-jobs-dialog';
import PublishHelpers from 'components/bricks/helpers/publish.helpers';
import { StartPublishStatus } from './components/start-status';
import PublishingProgress from './components/publishing-progress-status';
import { PublishValidationError } from '../../types/PublishTab.type';
import { BlockPublishStatus } from './components/block-status';

interface Props {
    currentBricks: Brick[];
    validationErrors?: PublishValidationError[];
    selectedPublishJob?: string;
    onPublish?: (publishJobs: BrickPublishJobData[]) => void;
}

const PublishStatus = ({ currentBricks, validationErrors, selectedPublishJob, onPublish }: Props) => {
    const [publishDialogOpen, setPublishDialogOpen] = useState(false);
    const brickIds = currentBricks.map((brick) => brick.id);
    const { publishJobs } = useBricksPublish(brickIds, selectedPublishJob);

    const status = PublishHelpers.getPublishStatus(publishJobs, validationErrors);

    const PublishStatusComponent = (() => {
        if (status === 'blocking') return <BlockPublishStatus currentBricks={currentBricks} />;
        if (!publishJobs) return <StartPublishStatus currentBricks={currentBricks} />;
        if (!publishJobs.length) return <StartPublishStatus currentBricks={currentBricks} />;

        if (status === 'readyToPublish') return <StartPublishStatus currentBricks={currentBricks} />;

        return (
            <PublishingProgress
                status={status}
                currentBricks={currentBricks}
                setPublishDialogOpen={setPublishDialogOpen}
                publishJobs={publishJobs}
                selectedPublishJob={selectedPublishJob}
            />
        );
    })();

    return (
        <>
            {PublishStatusComponent}

            {publishDialogOpen && currentBricks.length && (
                <OutputJobsDialog
                    outputAction={publishJobs?.[0].outputAction || 'publish'}
                    brickIds={brickIds}
                    onClose={() => setPublishDialogOpen(false)}
                    onActionFinish={onPublish}
                />
            )}
        </>
    );
};

export { PublishStatus };
